<template>
  <div>
    <div class="wrapper">
      <section class="section section--small-spacing-bottom">
        <div class="shell">
          <back-button
            :title="$t('views.legal.title')"
            @click="$router.push({ name: 'home' })"
          />
          <article class="section__body article-secondary">
            <section-block
              id="editor"
              :title="$t('views.legal.editor.title')"
              :content="destination.companyInfo"
            />
            <section-block
              id="pubmanager"
              :title="$t('views.legal.publicationManager.title')"
              :content="destination.publicationManager"
            />
            <section-block
              id="hosting"
              :title="$t('views.legal.hosting.title')"
              :content="destination.hostingInfo"
            />
            <section-block
              id="conception"
              :title="$t('views.legal.conception.title')"
              :content="$t('views.legal.conception.content')"
            />
          </article>
        </div>
      </section>
      <the-footer
        :logo="logoSrc"
        :logo-src-set="logoSrcSet"
      />
    </div>
  </div>
</template>

<script setup>
import BackButton from '../components/BtnBack.vue';
import SectionBlock from '../components/SectionHowItWorks.vue';
import TheFooter from '../components/TheFooter.vue';
import { useCompanyLogo } from '../composables/CompanyLogo';
import { useDestinationStore } from '../pinia/destination';
import { computed } from 'vue';

const { logoSrc, logoSrcSet} = useCompanyLogo();
const destinationStore = useDestinationStore();

const destination = computed(() => destinationStore.currentDestination);
</script>
<style>
footer {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}
</style>
